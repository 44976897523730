import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Est-ce que Recouvro est fiable ?",
      answer: "Oui, Recouvro est édité par DIGHITEC INNOVATIONS SARL, une entreprise légalement enregistrée au Bénin sous le N° RCCM RB/ABC/23 B 6286 et l'IFU : 3202322510966. Notre plateforme est développée selon les standards de l'industrie et bénéficie d'une maintenance régulière."
    },
    {
      question: "Est-ce que c'est un bon investissement pour mon agence ?",
      answer: "Absolument ! Nos clients constatent en moyenne une réduction de 50% du temps consacré à la gestion des loyers. Avec des forfaits à partir de 15 000 FCFA par mois, le retour sur investissement est rapide. De plus, vous économisez sur les coûts d'impression, de déplacement et de gestion manuelle des documents."
    },
    {
      question: "Que pensent les autres utilisateurs de Recouvro ?",
      answer: "Nos clients témoignent régulièrement de leur satisfaction. Les agences immobilières apprécient particulièrement l'automatisation des tâches répétitives et la réduction des erreurs. Vous pouvez consulter les témoignages de nos clients actuels dans la section témoignages ci-dessus, et nous pouvons vous mettre en contact avec certains de nos clients pour discuter directement de leur expérience."
    },
    {
      question: "Pourquoi choisir Recouvro plutôt qu'une solution manuelle ?",
      answer: "Bien que la gestion manuelle soit possible, Recouvro offre une automatisation qui élimine les erreurs, réduit le temps de gestion et améliore la satisfaction des locataires. Notre solution intégrée assure la conformité légale, la génération automatique des documents et un suivi en temps réel impossible à obtenir manuellement. Vous gagnez un temps précieux tout en professionnalisant votre gestion."
    },
    {
      question: "Est-ce que mon argent est en sécurité ?",
      answer: "Oui, votre argent est totalement sécurisé. Nous utilisons des services de paiement mobile agréés et sécurisés. Chaque transaction est tracée et documentée, avec des notifications en temps réel. Les paiements sont agrégés par Recouvro, et nous assurons une transparence totale sur toutes les transactions."
    },
    {
      question: "Comment assurez-vous la sécurité des données ?",
      answer: "La sécurité est notre priorité absolue. Nous utilisons le cryptage SSL/TLS pour toutes les transmissions de données, nos serveurs sont certifiés et régulièrement audités. Les accès aux données sensibles sont protégés par authentification à deux facteurs, et nous effectuons des tests de sécurité réguliers."
    },
    {
      question: "Qu'en est-il du support technique ?",
      answer: "Notre équipe de support est disponible pendant les heures de bureau via chat, email et téléphone. Le temps de réponse moyen est de moins de 2 heures. Les clients du plan Pro bénéficient d'un support prioritaire avec un temps de réponse garanti de 30 minutes. Nous proposons également une base de connaissances complète et des tutoriels vidéo."
    }
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Questions Fréquentes
          </h2>
          <p className="text-xl text-gray-600">
            Tout ce que vous devez savoir sur Recouvro
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden"
            >
              <button
                className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50 transition-colors"
                onClick={() => toggleAccordion(index)}
                aria-expanded={openIndex === index}
                aria-controls={`faq-answer-${index}`}
              >
                <span className="font-medium text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              {openIndex === index && (
                <div 
                  id={`faq-answer-${index}`}
                  className="px-6 py-4 bg-gray-50 border-t border-gray-200"
                >
                  <p className="text-gray-600">
                    {faq.answer}
                    {index === 5 && (
                      <Link to="/impact-social" className="text-blue-600 hover:text-blue-800 ml-1">
                        En savoir plus →
                      </Link>
                    )}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Vous avez d'autres questions ? {' '}
            <a href="#contact" className="text-blue-600 hover:text-blue-800">
              Contactez-nous
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;