import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider } from "./components/auth/AuthContext";
import { PrivateRoute } from "./components/auth/PrivateRoute";

// Pages
import HomePage from "./components/pages/HomePage";
import RegistrationPage from "./components/pages/RegistrationPage";
import LegalMentionsPage from './components/pages/LegalMentions';

// Admin Components
import AdminLogin from "./components/admin/AdminLogin";
import AdminLayout from "./components/admin/AdminLayout";
import Dashboard from "./components/admin/Dashboard";
import TenantList from "./components/admin/tenants/TenantList";
import CreateTenant from "./components/admin/tenants/CreateTenant";
import UpdateTenant from "./components/admin/tenants/UpdateTenant";
import WithdrawalList from "./components/admin/withdrawals/WithdrawalList";

import "./App.css";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Navigate to="/admin/login" replace />} />
                    <Route path="/register" element={<RegistrationPage />} />
                    <Route path="/legal-mentions" element={<LegalMentionsPage />} />
                    
                    {/* Admin routes */}
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute>
                                <AdminLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="tenants" element={<TenantList />} />
                        <Route path="tenants/new" element={<CreateTenant />} />
                        <Route path="tenants/:schema_name" element={<UpdateTenant />} />
                        <Route path="withdrawals" element={<WithdrawalList />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;