import React from 'react';
import { Clock, DollarSign, TrendingDown } from 'lucide-react';

const Benefits = () => {
  const mainBenefits = [
    { 
      icon: Clock, 
      title: "Gagnez +30h par mois", 
      description: "Libérez +30h par mois grâce à l'automatisation de la collecte des loyers et la génération des documents" 
    },
    { 
      icon: TrendingDown, 
      title: "20% Moins d'Impayés", 
      description: "Réduisez de 20% les retards de paiement et les impayés" 
    },
    { 
      icon: DollarSign, 
      title: "Économisez jusqu'à 80 000 FCFA par mois", 
      description: "Réduisez vos coûts opérationnels et optimisez votre trésorerie" 
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Changed grid layout to ensure proper centering with 3 items */}
        <div className="grid md:grid-cols-3 gap-8">
          {mainBenefits.map((benefit, index) => (
            <div key={index} className="bg-gradient-to-br from-white to-blue-50 rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg flex items-center justify-center mb-4">
                <benefit.icon className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;