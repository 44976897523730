import React from 'react';
import { Helmet } from 'react-helmet';  // If you want to handle meta tags
import Header from '../landing/Header';
import Hero from '../landing/Hero';
import Benefits from '../landing/Benefits';
import Features from '../landing/Features';
import Testimonials from '../landing/Testimonials';
import Pricing from '../landing/Pricing';
import FAQ from '../landing/Faq';
import Footer from '../landing/Footer';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Recouvro - Gestion Automatisée du Recouvrement</title>
        <meta 
          name="description" 
          content="Simplifiez la gestion de votre agence immobilière avec Recouvro. Automatisez vos encaissements de loyers et la génération de quittances." 
        />
      </Helmet>

      {/* Fixed Navigation */}
      <Header />

      {/* Main Content */}
      <main>
        {/* Hero Section with Video */}
        <section>
          <Hero />
        </section>

        {/* Benefits Section */}
        <section>
          <Benefits />
        </section>

        {/* Features Section */}
        <section id="features">
          <Features />
        </section>

        {/* Testimonials Section */}
        <section id="testimonials">
          <Testimonials />
        </section>

        {/* Pricing Section */}
        <section id="pricing">
          <Pricing />
        </section>
        <section id="faq">
          <FAQ />
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;